<template>
  <div class="login_container">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      auto-complete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">后台管理系统</h3>
      </div>
      <el-form-item prop="username">
        <el-input
          prefix-icon="iconfont icon-yonghuming"
          v-model="loginForm.username"
          name="username"
          type="text"
          tabindex="1"
          placeholder="请输入用户名"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          prefix-icon="iconfont icon-mima"
          v-model="loginForm.password"
          type="password"
          name="password"
          tabindex="2"
          placeholder="请输入密码"
          @keyup.enter.native="login"
        >
        </el-input>
      </el-form-item>
      <el-button
        :loading="loading"
        type="primary"
        style="width:100%;margin-bottom:30px;"
        @click.native.prevent="login"
        >登陆</el-button
      >
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      loading: false,
      redirect: undefined
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate(async valid => {
        if (!valid) return false
        this.loading = true
        const { data } = await this.$http.post('/admin/login', this.loginForm)
        if (data.code === 200) {
          this.$message.success('登陆成功')
          window.sessionStorage.setItem('token', data.data.token)
          this.$router.push({ path: this.redirect || '/' })
        } else {
          this.loading = false
          return this.$message.error('登陆失败')
        }
      })
    }
  }
}
</script>
<style lang="less">
@supports (-webkit-mask: none) and (not (cater-color: #fff)) {
  .login-container .el-input input {
    color: #fff;
  }
  .login_container {
    .el-input {
      display: inline-block;
      height: 47px;
      width: 85%;
      input {
        background: transparent !important;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 30px;
        color: #eee;
        height: 47px;
        caret-color: #fff;
        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px #283443 inset !important;
          -webkit-text-fill-color: #fff !important;
        }
      }
      .el-input__prefix {
        top: 2px;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.login_container {
  min-height: 100%;
  width: 100%;
  background-color: #2d3a4b;
  overflow: hidden;

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .title-container {
    position: relative;
    .title {
      font-size: 26px;
      color: #eee;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }
  /deep/.el-input__icon {
    font-size: 20px !important;
  }
}
</style>
